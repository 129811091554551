import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CrawlerLogsService {

  constructor(private http: HttpClient) { }

  list(): Observable<any>{
    return this.http.get(`/api/logs/all`);
  }

  delete(id): Observable<any>{
    return this.http.delete(`/api/logs/delete/${id}`);
  }

  deleteSelected(data): Observable<any>{
    return this.http.post(`/api/logs/delete`, data);
  }

}
