import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table'
import {NbDialogService} from "@nebular/theme";
import {ImageSliderComponent} from "../../pages/image-slider/image-slider.component";

@Component({
  template: `<div (click)="zoom(data)"><i class="fas fa-search-plus"></i> <span>Preview</span></div>`,
  styles: ['div{ display: inline !important; cursor: pointer !important;}',
           'span{ display: inline !important; cursor: pointer !important; padding-left: 10px;}',
           'a { font-weight: normal; font-size: 15px; margin: 0 5px;}',
           'a:hover{ text-decoration: none; }'],
})

export class ZoomImageSliderComponent implements ViewCell, OnInit {
  public data: any;

  @Input()
  public value: string;

  @Input()
  rowData: any;

  constructor(private nbDialogService: NbDialogService) {
  }

  ngOnInit() {
    this.data = this.value;
  }

  zoom(item) {
    this.nbDialogService.open(ImageSliderComponent,  {
      context: {
        data: item
      },
    });
  }

}
