import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginComponent } from './@theme/components/login/login.component';
import { TwoFactorAuthenticationComponent } from './@theme/components/two-factor-authentication/two-factor-authentication.component';
import { LogoutComponent } from './@theme/components/logout/logout.component';
import { NbAuthComponent } from '@nebular/auth';
import { RedirectionGuard } from "./@core/guards/redirection.guard";

export const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'xauth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
        canActivate: [RedirectionGuard],
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'confirm-otp',
        component: TwoFactorAuthenticationComponent,
      },
      {
        path: 'logout',
        component: LogoutComponent,
      }
    ],
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
