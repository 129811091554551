import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table'
import { GenerateReportsService } from "../../../@core/mock/generate-reports.service";
import { NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService, NbDialogService } from "@nebular/theme";
import { DatesComponent } from './dates/dates.component';
import { HelpersService } from "../../../@core/mock/helpers.service";

@Component({
  selector: 'ngx-download-report',
  styleUrls: ['./download-report.component.scss'],
  templateUrl: './download-report.component.html',
})

export class DownloadReportComponent implements ViewCell, OnInit {

  index = 1;
  destroyByClick = true;
  duration = 10000;
  hasIcon = false;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.BOTTOM_RIGHT;
  preventDuplicates = true;
  status: NbComponentStatus = 'primary';


  public data: any;
  public text = 'Download';


  start_date: any = null;
  end_date: any = null;
  id: any = null;
  filename: any = null;

  @Input()
  public value: string;

  @Input()
  rowData: any;

  constructor(private generateReportsService: GenerateReportsService,
              private toastrService: NbToastrService,
              private helpersService: HelpersService,
              private dialogService: NbDialogService) {
  }

  ngOnInit() {
    this.data = this.value;
  }

  ConvertToCSV(objArray, headerList) {
    this.text = 'Downloaded';
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
      row += headerList[index].replace(/,/g, '').replace('\n', '').replace('\r\n', '') + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i+1)+'';
      for (let index in headerList) {
        let head = headerList[index].replace(/,/g, '').replace('\n', '').replace('\r\n', '');
        line += ',' + array[i][head].replace(/,/g, '').replace('\n', '').replace('\r\n', '');
      }
      str += line + '\r\n';
    }
    return str;
  }

  exportexcel(id, filename): void {
    this.id = id;
    this.filename = filename;
    this.generateReportsService.view(id).subscribe(
      (data) => {
        if(data['query']['query'].toString().includes('#start_date') && data['query']['query'].toString().includes('#end_date')){
            this.openDatePickerForm();
        }else{
          this.getQueryData();
        }
      }
    );
  }

  openDatePickerForm() {
    this.dialogService.open(DatesComponent)
      .onClose.subscribe(dates =>{
      this.start_date = dates['start_date'];
      this.end_date = dates['end_date'];
      this.getQueryData();
    });
  }


  getQueryData(){
    this.text = 'Downloading...';
    let dates = this.helpersService.resolveDates([this.start_date, this.end_date]);
    this.generateReportsService
      .getData(this.id, dates[0].toLocaleDateString('en-CA'), dates[1].toLocaleDateString('en-CA'))
      .subscribe(
        (data) => {
          if(!data['data'][0]){
            this.showToast('warning', 'No data Found!', 'The data is empty, please select valid date.');
          }
          let csvData = this.ConvertToCSV(data['data'], Object.keys(data['data'][0]));
          let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
          let dwldLink = document.createElement("a");
          let url = URL.createObjectURL(blob);
          let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
          if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute("target", "_blank");
          }
          dwldLink.setAttribute("href", url);
          dwldLink.setAttribute("download", this.filename + ".csv");
          dwldLink.style.visibility = "hidden";
          document.body.appendChild(dwldLink);
          dwldLink.click();
          document.body.removeChild(dwldLink);
        });
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }

}
