import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'
import {AngularFireMessaging} from "@angular/fire/messaging";
import { DeviceRegistrationService } from './device-registration.service';
import { from } from 'rxjs';

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  currentRegisteredDevices = [];

  constructor(private angularFireMessaging: AngularFireMessaging,
              private deviceRegistrationService:DeviceRegistrationService) {
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        // console.log(token);
        this.deviceRegistrationService.list()
          .subscribe((data) => {
            this.currentRegisteredDevices = data['keys']
            if(this.currentRegisteredDevices.filter( it => it.token === token).length === 0){
              this.deviceRegistrationService.add({token}).subscribe();
            }
          });
      },
      (err) => {
        // console.error('Unable to get permission to notify.', err);
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        this.currentMessage.next(payload);
      });

    // from(this.angularFireMessaging.onBackgroundMessage(payload =>{
    //   // console.log("New message received in onBackgroundMessage function", payload);
    // })).subscribe(message => {})

    from(this.angularFireMessaging.onMessage(payload =>{
      // console.log("New message received in onMessage function", payload);
    })).subscribe(message => {})
  }
}
