<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img src="assets/icons/icon-256x256.png" class="small-osn-logo">
      <span class="logo-text">CSS</span>
    </a>
  </div>
<!--  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">-->
<!--    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>-->
<!--  </nb-select>-->
</div>

<div class="header-container">

  <nb-actions size="small">

<!--    <nb-action class="control-item">-->
<!--      <nb-search type="rotate-layout"></nb-search>-->
<!--    </nb-action>-->
    <nb-action *ngIf="logsCounter > 0" class="control-item"
               [routerLink]="'/pages/crawler-logs/list'"
               style="border-right: transparent !important; border-left: transparent !important;">
      <span class="logs_counter" [routerLink]="'/pages/crawler-logs/list'">{{logsCounter}}</span>
    </nb-action>
    <nb-action class="control-item" icon="bell-outline" [routerLink]="'/pages/crawler-logs/list'"></nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>

</div>
