import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  constructor() { }

  public convertUTCDateToLocalDate(date): string {
    date = new Date(date);
    var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate.toLocaleString();
  }

  public resolveDates(dates: Array<any>): Array<Date>{
    let newDates = []
    dates.forEach((value, index) => {
        newDates.push(new Date(new Date(value).toLocaleDateString('en-CA')));
    });
    return newDates;
  }

}

