import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Powered by OSN - 2021-{{year}}
    </span>
    <div class="socials"
         style="background-color: transparent; padding: 6px 10px; border-radius: 3px;">
      Release Version (1.0.0)
    </div>
  `,
})


export class FooterComponent {

  year = new Date().getFullYear();

}
