import { Component, Input, OnInit, TemplateRef, ViewChild  } from '@angular/core';
import { ViewCell } from 'ng2-smart-table'
import {GenerateReportsService} from "../../../@core/mock/generate-reports.service";
import {NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService, NbDialogService} from "@nebular/theme";
import { DatesComponent } from '../download-report/dates/dates.component';
import { HelpersService } from '../../../@core/mock/helpers.service';
import { LocalDataSource } from "ng2-smart-table";
import { NbWindowService } from '@nebular/theme';

@Component({
  selector: 'ngx-view-data',
  styleUrls: ['./view-data.component.scss'],
  templateUrl: './view-data.component.html',
})

export class ViewDataComponent implements ViewCell, OnInit {

  index = 1;
  destroyByClick = true;
  duration = 10000;
  hasIcon = false;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.BOTTOM_RIGHT;
  preventDuplicates = true;
  status: NbComponentStatus = 'primary';

  settings = {
    pager: {
      display: true,
      perPage: 10
    },
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    columns: {
    }
  };
  tableData: LocalDataSource = new LocalDataSource();
  public data: any;
  public text = 'View Data';
  @ViewChild('contentTemplate', { static: true }) contentTemplate: TemplateRef<any>;
  start_date: any = null;
  end_date: any = null;
  id: any = null;
  @Input()
  public value: string;
  @Input()
  rowData: any;

  constructor(private generateReportsService: GenerateReportsService,
              private toastrService: NbToastrService,
              private windowService: NbWindowService,
              private helpersService: HelpersService,
              private dialogService: NbDialogService) {
  }

  ngOnInit() {
    this.data = this.value;
  }

  viewDataWindow(): void {
      if(this.data.date_range === '1'){
          this.openDatePickerForm();
      }else{
        this.getQueryData();
      }
  }

  openDatePickerForm() {
    this.dialogService.open(DatesComponent)
      .onClose.subscribe(dates =>{
      this.start_date = dates['start_date'];
      this.end_date = dates['end_date'];
      this.getQueryData();
    });
  }


  getQueryData(){
    let dates = this.helpersService.resolveDates([this.start_date, this.end_date])
    this.generateReportsService
      .getData(this.data.id, dates[0].toLocaleDateString('en-CA'), dates[1].toLocaleDateString('en-CA'))
      .subscribe(
        (res) => {
          Object.keys(res.data[0]).forEach((key, i) => {
            this.settings.columns[key] = {
              title: `${key.replace('_', ' ')}`,
              type: 'string'
            };
          });
          this.tableData.load(res.data);
          this.windowService.open(
            this.contentTemplate,
            {
              title: `${this.data.fileName}`,
              context: {}
            },
          );
          if(!res['data'][0]){
            this.showToast('warning', 'No data Found!',
                           'The data is empty, please select valid date.');
          }
        });
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }

}
