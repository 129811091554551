<nb-alert *ngIf="setInstallable">
  <strong>Fast Access</strong> You can access the system faster by installing our app
  <button nbButton (click)="installApp()" [status]="'success'"
          hero style="display: block; margin: 0 auto; margin-top: 10px; width: 100%;">
    Install APP
  </button>
</nb-alert>
<img src="assets/icons/icon-256x256.png" class="big-osn-logo">
<h1 id="title" class="title">OSN CSS</h1>
<p class="sub-title">Google Authenticator Code</p>
<form [formGroup]="loginForm" (ngSubmit)="send()">
  <div class="form-control-group">
    <input nbinput
          fullwidth
          name="otp"
          id="otp"
          placeholder="Enter the 6 digits code"
          fieldsize="large"
          class="input-full-width size-large status-basic shape-rectangle" name="otp"
           formControlName="otp"
           [(ngModel)]="loginData.otp"
           required>
  </div>
  <button nbbutton fullwidth status="primary" size="large" aria-disabled="true" tabindex="-1"
          class="appearance-filled full-width size-large shape-rectangle status-primary"
          type="submit" [disabled]="loginForm.invalid">
    Log In
  </button>
</form>
<p class="sub-title">Remaining Time <span>00:{{remaining_time}}</span></p>
