import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../@core/mock/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import {NbComponentStatus, NbGlobalLogicalPosition, NbGlobalPhysicalPosition,
  NbGlobalPosition, NbToastrService, NbToastrConfig } from '@nebular/theme';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  config: NbToastrConfig;

  index = 1;
  destroyByClick = true;
  duration = 10000;
  hasIcon = false;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.BOTTOM_RIGHT;
  preventDuplicates = true;
  status: NbComponentStatus = 'primary';
  deferredPrompt;

  types: NbComponentStatus[] = [
    'primary',
    'success',
    'info',
    'warning',
    'danger',
  ];

  loginData = {email: '', password: null};
  loginForm: FormGroup;
  redirectUrl: string = '/';
  setInstallable: boolean = false;

  constructor(private auth: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private toastrService: NbToastrService) {
    this.loginForm = new FormGroup({
      'email': new FormControl(this.loginData.email, [Validators.required]),
      'password': new FormControl(this.loginData.password, [Validators.required])
    });
    this.route.queryParams.subscribe(params => {
      this.redirectUrl = params['redirect-url'] != undefined && !params['redirect-url'].includes('auth') ? params['redirect-url']: this.redirectUrl;
    });


    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      this.setInstallable = true;
    });

    window.addEventListener('appinstalled', () => {
      // Log install to analytics
      console.log('INSTALL: Success');
    });
  }

  send(){
    this.auth.login2f(this.loginData.email, this.loginData.password).subscribe(
      (done) => {
        this.router.navigateByUrl(`/xauth/confirm-otp?redirect-url=${this.redirectUrl}&request_id=${done['request_id']}`);
      },
      (err) => {
        this.showToast('danger', 'Error', JSON.stringify(err['error']));
      }
    )
  }

  installApp(){
    this.setInstallable = false;
    // Show the install prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
    });
  }

  ngOnInit(): void {
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }

}
