import {Component, Input, OnInit, Output} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-dates',
  templateUrl: './dates.component.html',
  styleUrls: ['./dates.component.scss']
})

export class DatesComponent implements OnInit {

  dates = {
    start_date: new Date(),
    end_date: new Date()
  }

  constructor(protected ref: NbDialogRef<DatesComponent>) {
  }

  ngOnInit() {
    this.dates.start_date = new Date(this.dates.start_date.setDate( this.dates.start_date.getMonth() - 1))
  }

  cancel() {
    this.ref.close();
  }

  submit() {
    this.ref.close(this.dates);
  }

}
