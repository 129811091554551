import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { SwUpdate } from "@angular/service-worker";

@Component({
  selector: 'ngx-app-update-dialog',
  templateUrl: 'app-update-dialog.component.html',
  styleUrls: ['app-update-dialog.component.scss'],
})
export class AppUpdateDialogComponent {

  @Input() title: string;

  constructor(protected ref: NbDialogRef<AppUpdateDialogComponent>,
              private readonly updates: SwUpdate) {}

  dismiss() {
    this.ref.close();
  }

  doAppUpdate() {
    this.updates.activateUpdate().then(() => document.location.reload());
  }
}
