import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table'

@Component({
  template: `
  <div class="table-links" *ngFor="let link of links">
    <a style="color:{{link?.color}};" *ngIf="!checkLink(link.link)" routerLink="{{link.link}}">
        <i class="{{link.icon}}"></i>
        <span class="text-overflow" *ngIf="link.text">{{link.text}}</span>
    </a>
    <a style="color:{{link?.color}};" [target]="link?.target" *ngIf="checkLink(link.link)" [href]="link.link">
      <i class="{{link.icon}}"></i>
      <span class="text-overflow" *ngIf="link.text">{{link.text}}</span>
    </a>
  </div>`,
  styles: ['div{ display: inline !important;}',
           'a { font-weight: normal; font-size: 15px; margin: 0 5px;}',
           'a:hover{ text-decoration: none; }'],
})

export class TableLinkComponent implements ViewCell, OnInit {
  public links: any;

  @Input()
  public value: string;

  @Input()
  rowData: any;

  ngOnInit() {
    this.links = this.value;
  }

  checkLink(link: string){
    if (link.startsWith('http')){
      return true
    }else {
      return false
    }
  }
}
