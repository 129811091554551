import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../@core/mock/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import {NbComponentStatus, NbGlobalLogicalPosition, NbGlobalPhysicalPosition,
  NbGlobalPosition, NbToastrService, NbToastrConfig } from '@nebular/theme';
import { ISubscription } from "rxjs/Subscription";
import { interval } from 'rxjs';
import {Observable} from 'rxjs/Rx';

@Component({
  selector: 'ngx-two-factor-authentication',
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./two-factor-authentication.component.scss']
})
export class TwoFactorAuthenticationComponent implements OnInit {

  config: NbToastrConfig;
  remaining_time = 60;
  count_down: ISubscription;
  index = 1;
  destroyByClick = true;
  duration = 10000;
  hasIcon = false;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.BOTTOM_RIGHT;
  preventDuplicates = true;
  status: NbComponentStatus = 'primary';
  deferredPrompt;

  types: NbComponentStatus[] = [
    'primary',
    'success',
    'info',
    'warning',
    'danger',
  ];

  loginData = {otp: null, request_id: null};
  loginForm: FormGroup;
  redirectUrl: string = '/';
  setInstallable: boolean = false;

  constructor(private auth: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private toastrService: NbToastrService) {

    this.loginForm = new FormGroup({
      'otp': new FormControl(this.loginData.otp, [Validators.required]),
      'request_id': new FormControl(this.loginData.request_id, [])
    });

    this.route.queryParams.subscribe(params => {
      this.redirectUrl =
        params['redirect-url'] != undefined && !params['redirect-url'].includes('auth') ?
          params['redirect-url']: this.redirectUrl;
      this.loginData.request_id = params['request_id'];
    });


    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      this.setInstallable = true;
    });

    window.addEventListener('appinstalled', () => {
      // Log install to analytics
      console.log('INSTALL: Success');
    });
  }

  send(){
    this.auth.confirmOtp(this.loginData).subscribe(
      (done) => {
        this.showToast('success', '', 'Successfully Logged In.');
        this.router.navigateByUrl(this.redirectUrl);
      },
      (err) => {
        this.showToast('danger', 'Error', JSON.stringify(err['error']));
      }
    )
  }

  installApp(){
    this.setInstallable = false;
    // Show the install prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
    });
  }

  ngOnInit(): void {
    this.count_down = Observable.interval(1000)
                      .subscribe(() =>{
                        this.remaining_time -= 1;
                      });
    setTimeout(() => {
      this.count_down.unsubscribe();
      this.router.navigateByUrl('/pages/dashboard');
    },60000);
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }

}
