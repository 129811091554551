import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../mock/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RedirectionGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.isAuthenticated().then(
      user => {
        this.route.queryParams.subscribe(params => {
          this.router.navigateByUrl(params['redirect-url']);
        });
        return false;
      },
      no => {
        return true;
      });
  }

}
