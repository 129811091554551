import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private http: HttpClient) { }

  async isAuthenticated() {
    const user = await this.Me().toPromise();
    if (user)
      return user;
    throw false;
  }

  Me(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      }),
    };
    return this.http.get(
      `/auth/me`,
      httpOptions).pipe(
      map((res: Response) => {
        return res;
      }),
    );
  }

  register(data): Observable<any>{
    return this.http.post(`/auth/register`, data);
  }

  google_authenticator_qrcode(user): Observable<any>{
    return this.http.get(`/auth/google-authenticator-qrcode/${user}`);
  }

  login2f(email: string, password: string): Observable<any> {
    return this.http.post(`/auth/login/2fa`, {email, password});
  }

  confirmOtp(data): Observable<any> {
    return this.http.post(`/auth/confirm/otp`, data);
  }

  logout(): Observable<any>{
    return this.http.post(`/auth/logout`, {});
  }
}
