<nb-card>
  <nb-card-header>Select dates</nb-card-header>
  <nb-card-body>
    <form>
      <div class="form-group row">
        <label for="start_date" class="label col-sm-3 col-form-label">Start Date *</label>
        <div class="col-sm-9">
          <input nbInput
                 type="text"
                 id="start_date"
                 fullWidth
                 placeholder="Start Date"
                 autocomplete="off"
                 name="start_date"
                 [(ngModel)]="dates.start_date"
                 [nbDatepicker]="formpicker">
          <nb-datepicker #formpicker></nb-datepicker>
        </div>
      </div>

      <div class="form-group row">
        <label for="end_date" class="label col-sm-3 col-form-label">End Date *</label>
        <div class="col-sm-9">
          <input nbInput
                 type="text"
                 id="end_date"
                 fullWidth
                 placeholder="End Date"
                 autocomplete="off"
                 name="end_date"
                 [(ngModel)]="dates.end_date"
                 [nbDatepicker]="formpicker2">
          <nb-datepicker #formpicker2></nb-datepicker>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <button class="cancel" nbButton (click)="cancel()">Cancel</button>
    <button nbButton status="success" (click)="submit()">Submit</button>
  </nb-card-footer>
</nb-card>









