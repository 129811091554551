<nb-card>
  <nb-card-header>{{ title }}</nb-card-header>
  <nb-card-body>
    Do you want to update the app now?
  </nb-card-body>
  <nb-card-footer style="overflow: hidden;">
    <button nbButton hero status="primary" (click)="doAppUpdate()">Yes</button>
    <button nbButton hero status="danger" style="float: right;" (click)="dismiss()">No</button>
  </nb-card-footer>
</nb-card>
