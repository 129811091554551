import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table'
import {GenerateReportsService} from "../../@core/mock/generate-reports.service";
import {NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService} from "@nebular/theme";

@Component({
  template: `<div (click)="exportexcel(data.id, data.fileName)"><i class="fas fa-download"></i> <span>{{text}}</span></div>`,
  styles: ['div{ display: inline !important; cursor: pointer !important;}',
           'span{ display: inline !important; cursor: pointer !important; padding-left: 10px;}',
           'a { font-weight: normal; font-size: 15px; margin: 0 5px;}',
           'a:hover{ text-decoration: none; }'],
})

export class DownloadReportComponent implements ViewCell, OnInit {

  index = 1;
  destroyByClick = true;
  duration = 10000;
  hasIcon = false;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.BOTTOM_RIGHT;
  preventDuplicates = true;
  status: NbComponentStatus = 'primary';


  public data: any;
  public text = 'Download';

  @Input()
  public value: string;

  @Input()
  rowData: any;

  constructor(private generateReportsService: GenerateReportsService, private toastrService: NbToastrService,) {
  }

  ngOnInit() {
    this.data = this.value;
  }

  ConvertToCSV(objArray, headerList) {
    this.text = 'Downloaded';
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i+1)+'';
      for (let index in headerList) {
        let head = headerList[index];
        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

  exportexcel(id, filename): void {
    this.text = 'Downloading...';
    let start_date = null;
    let end_date = null;
    if(localStorage.getItem('start_date')){
      try{
        start_date = new Date(localStorage.getItem('start_date'))
        start_date = new Date(start_date.setDate( start_date.getDate() + 1))
        start_date = start_date.toISOString();
      }catch (e) {}
    }

    if(localStorage.getItem('end_date')){
      try{
        end_date = new Date(localStorage.getItem('end_date'))
        end_date = new Date(end_date.setDate( end_date.getDate() + 1))
        end_date = end_date.toISOString();
      }catch (e) {}
    }

    this.generateReportsService
      .getData(id, start_date, end_date)
      .subscribe(
      (data) => {
        if(!data['data'][0]){
          this.showToast('warning', 'No data Found!', 'The data is empty, please select valid date.');
        }
        let csvData = this.ConvertToCSV(data['data'], Object.keys(data['data'][0]));
        let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
          dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
      });
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }

}
