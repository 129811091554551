import {Component, Input, OnInit} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-image-slider',
  templateUrl: 'image-slider.component.html',
  styleUrls: ['image-slider.component.scss'],
})
export class ImageSliderComponent implements OnInit{

  @Input() data: any;
  domain = '';

  constructor(protected ref: NbDialogRef<ImageSliderComponent>) {
  }

  cancel() {
    this.ref.close({});
  }


  ngOnInit(): void {
    this.domain = (new URL(window.location.href)).hostname;
  }
}
