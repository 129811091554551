import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { AuthService } from '../../../@core/mock/auth.service';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { CrawlerLogsService } from '../../../@core/mock/crawler-logs.service';
import {interval, Subject} from 'rxjs';
import {Observable} from 'rxjs/Rx';
import { ISubscription } from "rxjs/Subscription";
// import { WebsocketService } from '../../../@core/mock/socket.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  logs_check: ISubscription;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';
  userMenu = [ { title: 'Profile',  link:'/pages/users/update-profile' }, { title: 'Log out', link:'/xauth/logout' } ];
  logsCounter;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private authService: AuthService,
              private layoutService: LayoutService,
              private crawlerLogsService: CrawlerLogsService,
              // private websocketService: WebsocketService,
              private breakpointService: NbMediaBreakpointsService) {
    // this.websocketService.messages.subscribe(msg => {
    //   console.log("Response from websocket: ");
    //   console.log(msg);
    // });
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.authService.Me().subscribe(
      (user) => {
        this.user = { name: user[0].name, picture: 'assets/images/nick.png', id:user[0].ID_user };
        // this.websocketService.messages.next(this.user);
      }
    )

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.crawlerLogsService.list().subscribe(
      (data) => {
        this.logsCounter = data['logs'].length
      }
    )

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    // this.logs_check.unsubscribe();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }


}
