import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GenerateReportsService {

  constructor(private http: HttpClient) { }

  list(): Observable<any>{
    return this.http.get(`/api/generate-reports/list`)
  }

  getData(id, start_date = null, end_date = null): Observable<any>{
    return this.http.get(`/api/generate-reports/get-query-data/${id}/${start_date}/${end_date}`)
  }

  view(id): Observable<any>{
    return this.http.get(`/api/generate-reports/view/${id}`)
  }

  add(data): Observable<any>{
    return this.http.post('/api/generate-reports/add', data)
  }

  edit(id, data): Observable<any>{
    return this.http.put(`/api/generate-reports/update/${id}`, data)
  }

  delete(id): Observable<any>{
    return this.http.delete(`/api/generate-reports/delete/${id}`)
  }
}
