import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DeviceRegistrationService {

  constructor(private http: HttpClient) { }

  list(): Observable<any>{
    return this.http.get(`/api/devices/list`)
  }

  view(id): Observable<any>{
    return this.http.get(`/api/devices/view/${id}`)
  }

  add(data): Observable<any>{
    return this.http.post('/api/devices/add', data)
  }

  edit(id, data): Observable<any>{
    return this.http.put(`/api/devices/update/${id}`, data)
  }

  delete(id): Observable<any>{
    return this.http.delete(`/api/devices/delete/${id}`)
  }
}
