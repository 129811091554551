<nb-alert *ngIf="setInstallable">
  <strong>Fast Access</strong> You can access the system faster by installing our app
  <button nbButton (click)="installApp()" [status]="'success'" hero style="display: block; margin: 0 auto; margin-top: 10px; width: 100%;">
    Install APP
  </button>
</nb-alert>
<img src="assets/icons/icon-256x256.png" class="big-osn-logo">
<h1 id="title" class="title">OSN CSS</h1>
<p class="sub-title">Hello! Log in with your email.</p>
<form [formGroup]="loginForm" (ngSubmit)="send()">
  <div class="form-control-group">
    <label for="input-email" class="label">Email address:</label>
    <input nbinput
          fullwidth
          name="email"
          id="input-email"
          pattern=".+@.+\..+"
          placeholder="Email address"
          fieldsize="large"
          class="input-full-width size-large status-basic shape-rectangle"
           name="email"
           formControlName="email"
           [(ngModel)]="loginData.email"
          required>
  </div>
  <div class="form-control-group">
    <span class="label-with-link">
      <label for="input-password" class="label">Password:</label>
    </span>
    <input nbinput
           fullwidth
           name="password"
           type="password"
           id="input-password"
           placeholder="Password"
           fieldsize="large"
           class="input-full-width size-large status-basic shape-rectangle"
           required
           minlength="4"
           maxlength="50"
           formControlName="password"
           [(ngModel)]="loginData.password">
  </div>
  <button nbbutton fullwidth status="primary" size="large" aria-disabled="true" tabindex="-1"
          class="appearance-filled full-width size-large shape-rectangle status-primary"
          type="submit" [disabled]="loginForm.invalid">
    Log In
  </button>
</form>
