import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../@core/mock/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'ngx-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.auth.logout().subscribe(
      (done) => { this.router.navigateByUrl('/xauth'); },
      (err)=> {}
    )
  }

}
